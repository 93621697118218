<template>
 <div class="flex-container non-scrollable h-full">
   <page-title value="Profil utilisateur"/>
   <div class="scrollable" :class="!getIsBrowserApp ? 'pb-5' : ''">
     <form @submit.prevent="handleSubmit(!v$.$invalid)" class="p-fluid md:p-5">
       <div class="flex flex-column justify-content-between">
         <Card class="p-3">
           <template #header>
             <page-title value="Mot de passe"/>
           </template>
           <template #content>
             <div class="flex flex-column justify-center align-items-center mb-3 md:flex-row">
               <label for="oldPassword" :class="{'p-error':v$.oldPassword.$invalid && submitted}" class="block text-900 font-medium md:w-2">Ancien mot de passe</label>
               <InputText id="oldPassword" v-model="oldPassword" :class="{'p-invalid':v$.oldPassword.$invalid && submitted}" type="password" class="md:w-3" />
               <div v-if="v$.oldPassword.$silentErrors && submitted" class="ml-3">
                <span id="oldPassword-error" v-for="(error, index) of v$.oldPassword.$silentErrors" :key="index">
                  <InlineMessage>{{error.$message}}</InlineMessage>
                </span>
               </div>
             </div>
             <div v-if="oldPassword">
               <div class="flex flex-column justify-center align-items-center mb-3 md:flex-row">
                 <label for="newPassword" :class="{'p-error':v$.newPassword.$invalid && submitted}" class="block text-900 font-medium md:w-2">Nouveau mot de passe</label>
                 <InputText id="newPassword" v-model="newPassword" :class="{'p-invalid':v$.newPassword.$invalid && submitted}" type="password" class="md:w-3" />
                 <div v-if="v$.newPassword.$silentErrors && submitted" class="ml-3">
                  <span id="newPassword-error" v-for="(error, index) of v$.newPassword.$silentErrors" :key="index">
                    <InlineMessage>{{error.$message}}</InlineMessage>
                  </span>
                 </div>
               </div>
               <div class="flex flex-column justify-center align-items-center mb-3 md:flex-row">
                 <label for="newPasswordConfirm" :class="{'p-error':v$.newPasswordConfirm.$invalid && submitted}" class="md:w-2 block text-900 font-medium">Confirmation mot de passe</label>
                 <InputText id="newPasswordConfirm" v-model="newPasswordConfirm" :class="{'p-invalid':v$.newPasswordConfirm.$invalid && submitted}" type="password" class="md:w-3" />
                 <div v-if="v$.newPasswordConfirm.$silentErrors && submitted" class="ml-3">
                  <span id="newPasswordConfirm-error" v-for="(error, index) of v$.newPasswordConfirm.$silentErrors" :key="index">
                    <InlineMessage>{{error.$message}}</InlineMessage>
                  </span>
                 </div>
               </div>
             </div>
           </template>
         </Card>

         <div class="float-button" :class="!getIsBrowserApp ? 'mb-3' : ''">
           <Button label="Sauvegarder" type="submit" class="w-full p-3"></Button>
         </div>
       </div>
     </form>

     <div v-if="getIsBrowserApp" class="flex flex-column justify-content-center text-center align-items-center md:gap-4">
       <Button label="Générer le QR code pour accéder à l'application mobile" class="p-3 w-6" @click="generateMobileAccessQrCode"/>
       <canvas id="qr-code-app-mobile" class="w-3 h-3" height="100" width="100"/>
     </div>
     <div v-else class="flex flex-column justify-content-center text-center align-items-center mt-2">
       <Button label="Se déconnecter" class="p-3 w-6" @click="logout"/>
     </div>
     <div class="spacer"/>
   </div>
 </div>
</template>

<script>
import { useVuelidate } from "@vuelidate/core";
import {helpers, minLength, not, requiredIf, sameAs} from "@vuelidate/validators";
import QRious from 'qrious'
import IsBrowserAppMixin from "@/mixins/isBrowserAppMixin";
import logoutMixin from "@/mixins/logoutMixin";
import PageTitle from "@/components/PageTitle.vue";
export default {
  name: "UserProfile",
  components: {PageTitle},
  mixins: [IsBrowserAppMixin, logoutMixin],
  setup: () => ({ v$: useVuelidate() }),
  data () {
    return {
      submitted: false,
      oldPassword: '',
      newPassword: '',
      newPasswordConfirm: '',
    }
  },
  validations() {
    return {
      oldPassword: {
      },
      newPassword: {
        minLengthValue: helpers.withMessage('Le mot de passe doit faire au moins 8 caractères', minLength(8)),
        notSameAsOldPassword: helpers.withMessage('Le nouveau mot de passe doit être différent', not(sameAs(this.oldPassword))),
        requiredIfFunction: helpers.withMessage('Champ requis', requiredIf(() => !(!this.oldPassword)))
      },
      newPasswordConfirm: {
        sameAsPassword: helpers.withMessage('Les champs doivent être identiques', sameAs(this.newPassword)),
      },
    }
  },
  methods: {
    generateMobileAccessQrCode () {
      new QRious({
        element: document.getElementById('qr-code-app-mobile'),
        level: 'l',
        value: `${process.env.VUE_APP_FRONT_URL}mobile-society-access`
      });
    },
    async handleSubmit (isFormValid) {
      this.submitted = true
      if (!isFormValid) return
      try {
        this.$store.state.misc.loading = true
        // if (this.uploadedLogo !== null) {
        //   // change logo only when there is a file that has been uploaded
        //   const newLogo = await this.$store.dispatch('file/uploadFile', this.uploadedLogo)
        //   this.companyLogo = newLogo.id
        // }

        const payload = {
          oldPassword: this.oldPassword,
          newPassword: this.newPassword
        }
        const result = await this.$store.dispatch('auth/changePassword', payload)
        if (result === true) {
          this.$toast.add({severity:'success', summary:'Succès', detail:'Mot de passe mis à jour', life: 3000})
          this.submitted = false
          this.oldPassword = ''
          this.newPassword = ''
          this.newPasswordConfirm = ''
        }
        else this.$toast.add({severity:'error', summary:'Erreur', detail:'Veuillez vérifier vos informations', life: 3000})

      } catch (error) {
        this.$toast.add({severity:'error', summary:'Erreur', detail:'Veuillez vérifier vos informations', life: 3000});
      }
      this.$store.state.misc.loading = false
    }
  }
}
</script>

<style scoped>

</style>
